import { Checkmark, Close, IconButton } from 'nzk-react-components'
import React, { useMemo } from 'react'
import useConfirmModal from '../../../../../hooks/useConfirmModal'
import { useMutation, useQuery } from '@apollo/client'
import gql from 'graphql-tag'

interface IProps {
  zookeeperId: string
  onComplete?: () => void
}

const ToggleSyncButton = (props: IProps) => {
  const { data, loading } = useQuery(gql`
    query getSyncedStatus ($_id: String) {
      findUser(input: { _id: $_id }) {
        _id
        starTutoring {
          _id
          schedule {
            _id
            lineupId
            synced
          }
        }
      }
    }
  `, { variables: { _id: props.zookeeperId } })

  const [updateSchedule, { loading: updating }] = useMutation(gql`
    mutation updateSchedule ($zookeeperId: String!, $input: UpdateAssignmnentScheduleInput) {
      starTutoring_updateAssignmentsSchedule(zookeeperId: $zookeeperId, input:  $input) {
        _id
        lineupId
        synced
      }
    }
  `)

  const synced = useMemo(() => {
    return Boolean(data?.findUser?.starTutoring?.schedule?.synced)
  }, [data])

  const toggleSync = async () => {
    if (loading || updating) return
    await updateSchedule({
      variables: {
        zookeeperId: props.zookeeperId,
        input: {
          synced: !synced
        }
      }
    })
  }

  const confirmSync = useConfirmModal({
    title: "Are you sure?",
    message:
      "",
    onConfirm: () => toggleSync(),
    onCancel: () => {},
  })

  if (loading) return null

  return <>
    <IconButton onClick={confirmSync} theme={synced ? 'confirm' : 'red'} size='x-small' icon={
      synced ? <Checkmark /> : <Close />
    }>{synced ? 'Synced' : 'Not Synced'}</IconButton>
  </>
}

export default ToggleSyncButton
