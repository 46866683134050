import { Avatar, Button } from 'nzk-react-components';
import React from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'

const Wrapper = styled.div`
  padding: 10px;
  border-radius: 8px;
  > * {
    margin-bottom: 6px;
  }
  > :last-child {
    margin-bottom: 0;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    font-family: 'Rammetto one';
    font-size: 26px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
`

interface IProps {
  zookeeper: {
    _id: string
    username: string
    yearGroup: number
    avatar: {
      url: string
    }
  }
}

const Zookeeper = (props: IProps) => {
  return <Wrapper>
    <Avatar user={{ ...props.zookeeper, type: 'student' }} size='160px' />
    <div className='title'>{props.zookeeper.username}</div>
    <div>Year group: {props.zookeeper.yearGroup}</div>
    <div><Button size='x-small' theme='confirm' onClick={() => navigate(`/student/${props.zookeeper.username}`)}>Profile</Button></div>
  </Wrapper>
}

export default Zookeeper
