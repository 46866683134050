import { useMutation } from '@apollo/client';
import { Link } from 'gatsby';
import gql from 'graphql-tag';
import { Button, IconButton, Refresh } from 'nzk-react-components';
import React from 'react'
import { FormattedDate } from 'react-intl';
import styled from 'styled-components'
import AssignmentTemplateCard from './AssignmentTemplateCard';

const Wrapper = styled.div`
  > * {
    margin-bottom: 20px;
  }
  > :last-child {
    margin-bottom: 0;
  }
`

const SentAssignment = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 10px;
  .status {
    font-family: 'Rammetto One';
    margin-bottom: 8px;
  }
  .dates {
    margin-bottom: 8px;
    display: flex;
    flex-wrap: wrap;
    > * { margin-right: 30px; }
    > :last-child { margin-right: 0; }
  }
`

const Actions = styled.div`
  margin-top: 10px;
  display: flex;
  > * { margin-right: 10px; }
  > :last-child { margin-right: 0; }
`

interface IProps {
  zookeeperId: string
  refetch: () => void
  sentAssignments: {
    _id: string
    sentAt: Date
    assignment: {
      _id: string
      started: boolean
      completed: boolean
      startedAt?: Date
      shouldBeReviewed?: boolean
      completedAt?: Date
      writing?: {
        _id: string
        title: string
      }
      lessonEntry?: {
        _id: string
      }
    }
    template: {
      _id: string
      title: string
      description: string
      lessonId: string
      writingPrompt: {
        title: string
      }
      createdBy: {
        _id: string
        email: string
        name: string
        avatar: {
          url: string
        }
      }
      createdAt: Date
      updatedBy: {
        _id: string
        email: string
        name: string
        avatar: {
          url: string
        }
      }
      updatedAt: Date
    }
  }[]
}

const SentAssignments = (props: IProps) => {

  const [refresh, { loading }] = useMutation(gql`
    mutation refreshAssignment ($zookeeperId: String!, $templateId: String!) {
      starTutoring_refreshSentAssignment(zookeeperId: $zookeeperId, templateId: $templateId)
    }
  `)

  const onRefresh = async (templateId) => {
    if (loading) return
    await refresh({ variables: {
      zookeeperId: props.zookeeperId,
      templateId: templateId
    }})
  }

  return <Wrapper>
  {
    ([...props.sentAssignments] || []).sort((a, b) => new Date(b.sentAt).getTime() - new Date(a.sentAt).getTime()).map(s => {
      let status
      let color
      if (!s.assignment.started && !s.assignment.completed) {
        status = '🔴 NOT STARTED'
        color = 'rgb(220, 45, 51)'
      } else if (s.assignment.started && !s.assignment.completed) {
        status = '🟡 STARTED'
        color = 'rgb(242, 190, 33)'
      } else if (s.assignment.shouldBeReviewed) {
        status = '🟠 NEEDS REVIEW'
        color = 'rgb(242, 137, 0)'
      } else if (s.assignment.completed) {
        status = '🟢 COMPLETED'
        color = 'rgb(0, 207, 97)'
      }
    return <SentAssignment key={s._id}>
      <div className='header'>
        <div className='status' style={{ color }}>
          {status}
        </div>
        <div className='dates'>
          <div>
            Sent on <FormattedDate value={s.sentAt} />
          </div>
          { s.assignment.startedAt && <div>
            Started on <FormattedDate value={s.assignment.startedAt} />
          </div> }
          { s.assignment.completedAt && <div>
            Completed on <FormattedDate value={s.assignment.completedAt} />
          </div> }
        </div>
        
      </div>
      <AssignmentTemplateCard template={s.template} />
      <Actions>
        { s.assignment.writing && <Link to={`/star-tutoring/reviews/${s.assignment._id}`}>
          <Button theme='primary' size='x-small'>Review</Button>
        </Link> }
        <IconButton icon={<Refresh />} theme='orange' size='x-small' disabled={loading} onClick={() => onRefresh(s.template._id)}>
          { loading ? 'Loading...' : 'Refresh' }
        </IconButton>
      </Actions>
    </SentAssignment>})
  }
  </Wrapper>
}

export default SentAssignments
