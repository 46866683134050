import { TextInput } from 'grommet'
import 'twin.macro'
import { Button } from 'nzk-react-components'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import useAsync from '../../../../../hooks/useAsync'
import debounce from '../../../../../lib/debounce'
import useData from '../../data/AssignmentsLineup/useData'
import useScheduleData from '../../data/AssignmentsSchedule/useData'
import useConfirmModal from '../../../../../hooks/useConfirmModal'

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: rgba(0,0,0,0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Wrapper = styled.div`
  position: relative;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
`

const Results = styled.div`
  bottom: 0;
  background-color: #fff;
  margin-top: 10px;
  box-shadow: 2px 1px 3px rgba(0,0,0,0.4);
  width: 100%;
  z-index: 101;
`

const Result = styled.div`
  padding: 10px;
  cursor: pointer;
  :hover {
    background-color: #ebebeb;
  }
`

const Value = styled.div`
  display: flex;
  align-items: center;
  > :first-child {
    margin-right: 12px;
  }
`

interface IProps {
  zookeeperId: string
  onComplete?: () => void
}

const RegenerateScheduleButton = (props: IProps) => {
  const [text, setText] = useState('') 
  const [expanded, setExpanded] = useState(false)
  const [loading, setLoading] = useState(false)
  const { searchAssignmnentsLineups } = useData()
  const { regenerateSchedule } = useScheduleData()

  const { data, execute } = useAsync({
    asyncFunc: async (params) => {
      return searchAssignmnentsLineups({
        filters: {
          title: { regex: params.titleLike }
        },
        skip: 0,
        limit: 3
      })
    },
    funcParams: {
      titleLike: text
    },
    immediate: false,
    initialData: []
  })

  const debouncedExecute = useMemo(() => debounce(execute, 1000), [])

  useEffect(() => {
    debouncedExecute({ titleLike: text })
  }, [text])

  const regenerate = async (lineupId: string) => {
    if (loading) return
    setLoading(true)
    await regenerateSchedule(props.zookeeperId, lineupId)
    setLoading(false)
    setExpanded(false)
    if (props.onComplete) props.onComplete()
  }

  const confirmRegenerate = useConfirmModal({
    title: "Are you sure?",
    message:
      "This will override the entire schedule for this child to the one you selected!",
    onConfirm: (lineupId: string) => regenerate(lineupId),
    onCancel: () => {},
  })



  return <>
    <Button size='small' theme='orange' onClick={() => setExpanded(true)}>Change schedule</Button>
    { expanded && <Overlay onClick={() => setExpanded(false)}>
      <Wrapper onClick={(e) => {
        e.stopPropagation()
      }}>
        <h3>Change to lineup</h3>
        <TextInput value={text} onChange={e => setText(e.target.value)} placeholder='Lineup title' />
        <div tw='mt-2'>Results:</div>
        <Results>
          {
            (data || []).map(d => <Result key={d._id} onClick={() => confirmRegenerate(d._id)}>{d.title}</Result>)
          }
        </Results>
      </Wrapper>
    </Overlay> }
  </>
}

export default RegenerateScheduleButton
