import 'twin.macro'
import { Button } from 'nzk-react-components'
import React, { useState } from 'react'
import styled from 'styled-components'
import useScheduleData from '../../data/AssignmentsSchedule/useData'
import useConfirmModal from '../../../../../hooks/useConfirmModal'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: rgba(0,0,0,0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

interface IProps {
  zookeeperId: string
  onComplete?: () => void
}

const RefreshScheduleButton = (props: IProps) => {
  const [loading, setLoading] = useState(false)
  const { regenerateSchedule } = useScheduleData()

  const { data } = useQuery(gql`
    query getSyncedStatus ($_id: String) {
      findUser(input: { _id: $_id }) {
        _id
        starTutoring {
          _id
          schedule {
            _id
            lineupId
            synced
          }
        }
      }
    }
  `, { variables: { _id: props.zookeeperId } })

  const regenerate = async () => {
    if (loading) return
    if (!data?.findUser?.starTutoring?.schedule?.lineupId) return
    setLoading(true)
    await regenerateSchedule(props.zookeeperId, data.findUser.starTutoring.schedule.lineupId)
    props.onComplete && props.onComplete()
    setLoading(false)
  }

  const confirmRegenerate = useConfirmModal({
    title: "Are you sure?",
    message:
      "This will override the entire schedule for this child to the one you selected!",
    onConfirm: (lineupId: string) => regenerate(),
    onCancel: () => {},
  })


  if (!data?.findUser?.starTutoring?.schedule?.lineupId) return null
  return <>
    <Button size='small' theme='orange' onClick={confirmRegenerate}>Regenerate</Button>
  </>
}

export default RefreshScheduleButton
