import React from 'react'
import ZookeeperPage from '../../../components/pages/StarTutoring/ZookeeperPage'
import StarTutoringLayout from '../../../components/pages/StarTutoring/StarTutoringLayout'

interface IProps {
  path: string
  params: {
    username: string
  }
}

const StarTutoringZookeeperPage = (props: IProps) => {
  return <StarTutoringLayout path={props.path}>
    <ZookeeperPage username={props.params.username} />
  </StarTutoringLayout>
}

export default StarTutoringZookeeperPage
