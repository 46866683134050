import { useApolloClient } from '@apollo/client'
import ADD_ASSIGNMENT_TEMPLATE_TO_SCHEDULE from './graphql/addAssignmentTemplateToSchedule.graphql'
import REMOVE_ASSIGNMENT_TEMPLATE_FROM_SCHEDULE from './graphql/removeAssignmentTemplateFromSchedule.graphql'
import SEND_NEXT_ASSIGNMENT from './graphql/sendNextAssignment.graphql'
import REGENERATE_SCHEDULE from './graphql/regenerateSchedule.graphql'

const useData = () => {
  const client = useApolloClient()

  const addAssignmentTemplateToSchedule = async (templateId: string, zookeeperId: string, after?: string) => {
    const { data } = await client.mutate({
      mutation: ADD_ASSIGNMENT_TEMPLATE_TO_SCHEDULE,
      variables: {
        templateId,
        zookeeperId,
        after
      }
    })
    return data.starTutoring_addAssignmentTemplateToLineup
  }

  const removeAssignmentTemplateFromSchedule = async (templateId: string, zookeeperId: string) => {
    const { data } = await client.mutate({
      mutation: REMOVE_ASSIGNMENT_TEMPLATE_FROM_SCHEDULE,
      variables: {
        templateId,
        zookeeperId,
      }
    })
    return data.starTutoring_removeAssignmentTemplateFromLineup
  }

  const sendNextAssignment = async (zookeeperId) => {
    const { data } = await client.mutate({
      mutation: SEND_NEXT_ASSIGNMENT,
      variables: {
        zookeeperId,
      }
    })
    return data.starTutoring_sendNextAssignment
  }

  const regenerateSchedule = async (zookeeperId: string, lineupId: string) => {
    const { data } = await client.mutate({
      mutation: REGENERATE_SCHEDULE,
      variables: {
        zookeeperId,
        lineupId
      }
    })
    return data.starTutoring_regenerateSchedule
  }


  return {
    addAssignmentTemplateToSchedule,
    sendNextAssignment,
    removeAssignmentTemplateFromSchedule,
    regenerateSchedule,
  }
}

export default useData
