import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components';
import { Button, Checkmark, Close, IconButton, Refresh } from 'nzk-react-components';
import useData from '../data/Subscription/useData';
import useAssignmentTemplateData from '../data/AssignmentTemplate/useData';
import useAssignmentsScheduleData from '../data/AssignmentsSchedule/useData'
import DraggableLists from './components/DraggableLists';
import Loader from '../../../UI/Loader'
import SentAssignments from './components/SentAssignments';
import Subscription from './components/Subscription';
import ZookeeperHero from './components/ZookeeperHero';
import RegenerateScheduleButton from '../components/RegenerateScheduleButton';
import ToggleSyncButton from '../components/ToggleSyncButton';
import RefreshScheduleButton from '../components/RefreshScheduleButton';

const Wrapper = styled.div``

const Section = styled.div`
  margin: 20px 0;
  > .title {
    font-size: 18px;
    font-family: 'Rammetto One';
    margin-bottom: 10px;
  }
  
  > .schedule-editor > :first-child {
    margin-top: 10px;
  }
  > .actions {
    display: flex;
    align-items: center;
    > * { margin-right: 10px; }
    > :last-child { margin-right: 0; }
  }
`

const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  > * {
    margin-right: 20px;
  }
  > :last-child {
    margin-right: 0;
  }
`

const ZookeeperPage = (props: { username: string }) => {
  const [data, setData] = useState<any>(null)
  const [templates, setTemplates] = useState([])
  const { fetchAssignmentTemplates } = useAssignmentTemplateData()
  const { sendNextAssignment } = useAssignmentsScheduleData()
  const [loading, setLoading] = useState(false)
  const { getInfo } = useData()

  const fetch = async (refetch?: boolean) => {
    setLoading(true)
    const data = await getInfo(props.username, refetch)
    const templates = await fetchAssignmentTemplates(0,1000,refetch)
    setTemplates(templates)
    setData(data)
    setLoading(false)
  }

  useEffect(() => {
    const queryParams = typeof window !== 'undefined'
      ? new URLSearchParams(window.location.search)
      : null

    fetch(queryParams?.get('refetch') === 'true')
  }, [])

  const onSendNextAssignment = async (id: string) => {
    await sendNextAssignment(id)
    await fetch(true)
  }

  const available = useMemo(() => {
    if (!data || !templates) return []
    const ids = [
      ...(data.starTutoring?.schedule?.assignmentTemplates || []).map(d => d?._id),
      ...(data.starTutoring?.sentAssignments || []).map(d => d.template?._id)
    ].filter(d => d)
    return templates.filter(({ _id }) => ids.indexOf(_id) < 0)
  }, [data, templates])

  if (loading) return <Loader />
  if (!data) return <div>No data?</div>
  return <Wrapper>
    <Header>
      <IconButton size='x-small' theme='primary' icon={<Refresh />} onClick={() => fetch(true)}>Refresh</IconButton>
    </Header>
    <ZookeeperHero zookeeper={data} />
    <Section>
      <div className='title'>Subscription</div>
      <Subscription starTutoring={data?.starTutoring} />
    </Section>
    <Section>
      <div className='title'>History</div>
      <SentAssignments zookeeperId={data._id} refetch={() => fetch(true)} sentAssignments={data?.starTutoring?.sentAssignments || []} />
    </Section>
    <Section>
      <div className='title'>Schedule</div>
      <div className='actions'>
        <Button theme='confirm' size='small' onClick={() => onSendNextAssignment(data._id)}>Send next assignment</Button>
        <RegenerateScheduleButton zookeeperId={data._id} onComplete={() => {
          fetch(true)
        }} />
        <RefreshScheduleButton zookeeperId={data._id} onComplete={() => { fetch(true) }} />
        <ToggleSyncButton zookeeperId={data._id} />
      </div>
      <div className='schedule-editor'>
        <DraggableLists zookeeperId={data._id} linedUp={(data.starTutoring?.schedule?.assignmentTemplates || []).filter(d => d)} available={available} />
      </div>
    </Section>
  </Wrapper>
}

export default ZookeeperPage
