import { Link } from 'gatsby'
import { Avatar, Button, Eye } from 'nzk-react-components'
import React from 'react'
import { FormattedDate } from 'react-intl'
import styled, { css } from 'styled-components'

const Wrapper = styled.div<{ dragging: boolean }>`
  position: relative;
  padding: 16px;
  border-radius: 6px;
  ${props => props.dragging && css`
    opacity: 0.9;
    animation: dragging-anim 0.2s infinite alternate-reverse linear;
  `}
  @keyframes dragging-anim {
    from {
      transform: rotate(1deg);
    }
    to {
      transform: rotate(-1deg);
    }
  }

  .title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 14px;
  }
  .description {
    margin-bottom: 14px;
  }
  .status-informations {
    display: flex;
    flex-wrap: wrap;
    > div {
      display: flex;
      line-height: 20px;
      align-items: center;
      > * { margin: 0 8px; }
    }
    > * { margin-left: 8px; }
    > :first-child { margin-left: 0; }
  }
  .link {
    position: absolute;
    top: 8px;
    right: 8px;
  }
`

interface IProps {
  dragging?: boolean
  template: {
    _id: string
    title: string
    description: string
    lessonId?: string
    // eslint-disable-next-line
    writingPrompt?: {
      title?: string
      description?: string
    }
    createdBy?: any
    createdAt?: Date
    updatedBy?: any
    updatedAt?: Date
  }
}

const AssignmentTemplateCard = (props: IProps) => {
  const type = props.template?.lessonId ? 'LESSON' : 'WRITING'

  if (!props.template) return null

  return <Wrapper dragging={Boolean(props.dragging)} style={{
    backgroundColor: type === "LESSON" ? '#ebebeb' : '#ebebeb'
  }}>
    <div className='title'>{ type === 'LESSON' ? '📘' : '📝'} {props.template?.title}</div>
    { props.template?.description && <div className='description' dangerouslySetInnerHTML={{ __html: props.template?.description }} /> }
    <div className='status-informations'>
      { props.template?.createdBy && <div>Created by <Avatar user={props.template.createdBy} size='20px' /> {props.template.createdBy.name} on <FormattedDate value={props.template.createdAt} /></div> }
      { props.template?.updatedBy && <div>-- &nbsp;Updated by <Avatar user={props.template.updatedBy} size='20px' /> {props.template.updatedBy.name} on <FormattedDate value={props.template.updatedAt} /></div> }
    </div>
    { props.template && <div className='link'>
      <Link to={`/star-tutoring/assignment-templates/${props.template._id}`}>
        <Button size='x-small' theme='primary' round>
          <Eye />
        </Button>
      </Link>
    </div> }
  </Wrapper>
}

AssignmentTemplateCard.defaultProps = {
  dragging: false
}

export default AssignmentTemplateCard
