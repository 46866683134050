import React from 'react'
import { FormattedDate } from 'react-intl';
import styled from 'styled-components'

const Wrapper = styled.div`
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
  > * {
    margin-bottom: 6px;
  }
  > :last-child {
    margin-bottom: 0;
  }
`

interface IProps {
  starTutoring: {
    createdAt: Date
    subscriptionId: string
    subscriptionType: "CUSTOM" | "STRIPE"
    subscriptionExpiresAt: Date
    message: string
    nextAssignmentDate: Date
  }
}

const Subscription = (props: IProps) => {
  return <Wrapper>
    <div><b>Subscription ID:</b> { props.starTutoring.subscriptionId }</div>
    <div><b>Subscribed on:</b> { props.starTutoring.createdAt
      ? <FormattedDate value={props.starTutoring.createdAt} />
      : 'Unknown'
    }</div>
    <div><b>Subscription type:</b> { props.starTutoring.subscriptionType }</div>
    <div><b>Expires:</b> <FormattedDate value={props.starTutoring.subscriptionExpiresAt} /></div>
    <div><b>Next assignment:</b> { props.starTutoring.nextAssignmentDate
      ? <FormattedDate value={props.starTutoring.nextAssignmentDate} />
      : 'Unscheduled'
    }</div>
    <div><b>Message:</b> {props.starTutoring.message || 'No message.'}</div>
  </Wrapper>
}

export default Subscription
